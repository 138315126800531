import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

export interface PowerStateChartProps {
  data: { numVms: number; powerState: string; color: string }[];
}

export const PowerStateChart: React.FC<PowerStateChartProps> = ({ data }) => {
  return (
    <ResponsiveBar
      data={data}
      layout="horizontal"
      keys={['numVms']}
      indexBy="powerState"
      margin={{ top: 10, right: 20, bottom: 20, left: 100 }}
      colors={({ data }) => data.color}
      defs={[]}
      fill={[]}
      borderColor={{ from: 'color', modifiers: [['darker', 1.4]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: 35,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: -40,
      }}
      enableLabel={true}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      legends={[]}
    />
  );
};

export default PowerStateChart;
